<template>
  <context-menu
    ref="contextMenu"
    class="task-context-menu">
    <li
      v-for="option in contextMenuOptions"
      :key="`task_context_menu_${option.key}`"
      @click="onSelect(option)">
      {{ option.label }}
    </li>
  </context-menu>
</template>

<script>
import taskContextMenuMixin from '@/mixins/task-context-menu-mixin'

const ContextMenu = () => import('vue-context-menu')

export default {
  name: 'task-context-menu',
  components: {
    ContextMenu
  },
  mixins: [taskContextMenuMixin],
  methods: {
    open(task) {
      this.selectedTask = task
      this.$refs.contextMenu.open()
    },
    onSelect(value) {
      this.$emit('select', this.selectedTask, value)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-context-menu {
  li {
    width: 192px;
    @extend %small;
  }
}
</style>
